import { Link } from 'gatsby';
import React from 'react';
import Categories from '../components/Categories';

const Category = ({ pageContext }) => {
    const { category } = pageContext;
    const posts = category.posts;

    return (
        <>
            <Categories />
            <h1>{category.name}</h1>
            {posts ? (
                <div className='uk-child-width-expand@s uk-child-width-1-3@m uk-grid-small' uk-grid='masonry: true'>
                    {posts.map((post, i) => {
                        return (
                            <div>
                                <div className='uk-margin uk-card uk-card-default'>
                                    <div className='uk-card-header'>
                                        <div
                                            className='uk-grid-small uk-flex-middle uk-grid'
                                        >
                                            <div className='uk-width-expand'>
                                                <h3 className='uk-card-title uk-margin-remove-bottom'>
                                                    {post.title}
                                                </h3>
                                                <p className='uk-text-meta uk-margin-remove-top'>
                                                    <time
                                                        datetime={
                                                            post.publishedAt
                                                        }
                                                    >
                                                        {post.publishedAt}
                                                    </time>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='uk-card-body'>
                                        <p>
                                            {
                                                post.childContentMdx.childMdx
                                                    .excerpt
                                            }
                                        </p>
                                    </div>
                                    <div class='uk-card-footer'>
                                        <Link
                                            to={
                                                '/blog/' +
                                                category.slug +
                                                '/' +
                                                post.slug
                                            }
                                            className='uk-button uk-button-text'
                                        >
                                            Read more
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        );
                    })}
                </div>
            ) : (
                <></>
            )}
        </>
    );
};

export default Category;
